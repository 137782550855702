<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="cari"
            >Cari</label>
            <div class="uk-form-controls ">
              <input
                id="search-name"
                v-model="metaFilter.name"
                class="uk-input"
                type="text"
                placeholder="Cari kode, peternakan, mitra..."
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="status"
            >
              Status
            </label>
            <div class="uk-form-controls ">
              <multiselect
                id="status"
                v-model="statusChoose"
                v-validate="'required'"
                name="status"
                label="name"
                track-by="name"
                placeholder="Semua"
                :options="farmstatus"
                open-direction="bottom"
                :loading="isLoading"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
              />
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="total_cages"
            >
              Total Kandang
            </label>
            <div class="uk-form-controls">
              <multiselect
                id="total_cages"
                v-model="cageChoose"
                v-validate="'required'"
                name="total_cages"
                track-by="name"
                label="name"
                :options="totalcage"
                placeholder="Pilih..."
                open-direction="bottom"
                :searchable="true"
                :loading="isLoading"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
              />
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="tanggal_diubah"
            >Tanggal Terdaftar</label>
            <div class="uk-form-controls ">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinStart"
                    v-model="metaFilter.join_start"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />  
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinEnd"
                    v-model="metaFilter.join_end"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="lokasi"
            >Kota/Kabupaten</label>
            <div class="uk-form-controls">
              <multiselect 
                id="city" 
                v-model="city_choose" 
                label="city" 
                track-by="city" 
                placeholder="Pilih kota/kabupaten..." 
                open-direction="bottom" 
                :options="city_locations" 
                :searchable="true" 
                :loading="isLoading"
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-text-right">
            <button
              class="uk-button uk-button-default uk-margin-left uk-width-auto"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-left uk-width-auto"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <router-link
        v-if="isCanAccessUser(`add`, `Peternakan`)"
        class="uk-button uk-button-default"
        to="/admin/peternakan/add"
      >
        <span
          uk-icon="icon: plus; ratio: 0.7"
          class="uk-margin-small-right"
        />Tambah Peternakan
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { dateString } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      statusChoose:'',
      cageChoose:'',
      metaFilter: {
        name: '',
        status: '',
        city: '',
        total_cage: '',
        join_start: '',
        join_end: ''
      },
      city_choose: '',
      city_locations: [],
      totalcage:[
        {name:1, value:1},
        {name:2, value:2},
        {name:3, value:3},
        {name:4, value:4}
      ],
      farmstatus:[
        {name:'Aktif', value:'true'},
        {name:'Tidak Aktif', value:'false'}
      ]
    }
  },
  computed : {
    ...mapGetters({
      meta: 'farm/meta',
      cities: 'city/cities'
    })
  },
  watch: {
    'metaFilter.join_start'() {
      this.metaFilter.join_start = this.dateFormatFilter(this.metaFilter.join_start)
    },
    'metaFilter.join_end'() {
      this.metaFilter.join_end = this.dateFormatFilter(this.metaFilter.join_end)
    }
  },
  async mounted() {
    await this.getCity({is_all: true})
    this.city_locations = this.cities ? this.cities : []
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getCity: 'city/getCity'
    }),
    ...mapMutations({
      setMeta: 'farm/SET_META'
    }),
    handleFind () {
      const getCityID = this.city_choose ? this.city_choose.id ? this.city_choose.id : '' : ''
      this.metaFilter.city = getCityID
      this.metaFilter.page = 1
      this.metaFilter.status = this.statusChoose ? this.statusChoose.value ? this.statusChoose.value : '' : ''
      this.metaFilter.total_cage = this.cageChoose ? this.cageChoose.value ? this.cageChoose.value : '' : ''
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      this.metaFilter.name = ''
      this.metaFilter.status = ''
      this.metaFilter.city = ''
      this.metaFilter.total_cage = ''
      this.metaFilter.join_start = ''
      this.metaFilter.join_end = ''
      this.city_choose = ''
      this.statusChoose=''
      this.cageChoose = ''

      await this.handleFind()
    }
  }
}
</script>
