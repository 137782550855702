<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-expand">
              <div class="uk-flex uk-flex-middle">
                <span class="uk-text-bold uk-width-expand">Kode Peternakan</span>
                <div class="uk-float-right uk-flex-column uk-width-auto">
                  <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
                    <div v-if="sortCode == 'farmCodeDown'">
                      <div class="sort-icon">
                        <img
                          :src="`${images}/icon/Vectorupper.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('farmCodeUp'), changeSort('order_by', 'farm_code', 'sort_by', 'ASC')"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          :src="`${images}/icon/VectordownInnactive.svg`"
                          alt=""
                          class="img-click"
                        >
                      </div>
                    </div>
                    <div v-else-if="sortCode == 'farmCodeUp'">
                      <div class="sort-icon">
                        <img
                          :src="`${images}/icon/VectorupperInnactive.svg`"
                          alt=""
                          class="img-click"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          :src="`${images}/icon/Vectordown.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('farmCodeDown'), changeSort('order_by', 'farm_code', 'sort_by', 'DESC')"
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="uk-margin-small-bottom">
                        <div class="sort-icon">
                          <img
                            :src="`${images}/icon/Vectorupper.svg`"
                            alt=""
                            class="img-click"
                            @click="sortSwitch('farmCodeUp'), changeSort('order_by', 'farm_code', 'sort_by', 'ASC')"
                          >
                        </div>
                        <div class="sort-icon-down">
                          <img
                            :src="`${images}/icon/Vectordown.svg`"
                            alt=""
                            class="img-click"
                            @click="sortSwitch('farmCodeDown'), changeSort('order_by', 'farm_code', 'sort_by', 'DESC')"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="uk-table-expand uk-width-small">
              <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
                <span class="uk-text-bold">Nama Peternakan</span>
              </div>
            </th>
            <th class="uk-table-shrink">
              <span class="uk-text-bold">Total Kandang</span>
            </th>
            <th class="uk-table-small uk-text-center">
              <div class="uk-flex uk-flex-center uk-margin-small-bottom">
                <span class="uk-text-bold">Status</span>
              </div>
            </th>
            <th class="uk-width-auto">
              <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
                <span class="uk-text-bold">Kota/Kabupaten</span>
              </div>
            </th>
            <th class="uk-table-expand">
              <div class="uk-flex uk-flex-middle">
                <span class="uk-text-bold uk-width-expand">Tanggal Terdaftar</span>
                <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
                  <div v-if="sortCode == 'createUp'">
                    <div class="sort-icon">
                      <img
                        v-lazy="`${images}/icon/Vectorupper.svg`"
                        alt=""
                        class="img-click"
                        @click="sortSwitch('createDown'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                      >
                    </div>
                    <div class="sort-icon-down">
                      <img
                        v-lazy="`${images}/icon/VectordownInnactive.svg`"
                        alt=""
                        class="img-click"
                      >
                    </div>
                  </div>
                  <div v-else-if="sortCode == 'createDown'">
                    <div class="sort-icon">
                      <img
                        v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                        alt=""
                        class="img-click"
                      >
                    </div>
                    <div class="sort-icon-down">
                      <img
                        v-lazy="`${images}/icon/Vectordown.svg`"
                        alt=""
                        class="img-click"
                        @click="sortSwitch('createUp'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div class="sort-icon">
                      <img
                        v-lazy="`${images}/icon/Vectorupper.svg`"
                        alt=""
                        class="img-click"
                        @click="sortSwitch('createDown'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                      >
                    </div>
                    <div class="sort-icon-down">
                      <img
                        v-lazy="`${images}/icon/Vectordown.svg`"
                        alt=""
                        class="img-click"
                        @click="sortSwitch('createUp'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="uk-table-expand uk-width-small">
              <div class="uk-flex uk-flex-middle">
                <span class="uk-text-bold uk-width-expand">Terakhir Diubah</span>
                <div class="uk-float-right uk-flex-column uk-width-auto">
                  <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
                    <div v-if="sortCode == 'updateDown'">
                      <div class="sort-icon">
                        <img
                          v-lazy="`${images}/icon/Vectorupper.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('updateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          v-lazy="`${images}/icon/VectordownInnactive.svg`"
                          alt=""
                          class="img-click"
                        >
                      </div>
                    </div>
                    <div v-else-if="sortCode == 'updateUp'">
                      <div class="sort-icon">
                        <img
                          v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                          alt=""
                          class="img-click"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          v-lazy="`${images}/icon/Vectordown.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('updateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="sort-icon">
                        <img
                          v-lazy="`${images}/icon/Vectorupper.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('updateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                        >
                      </div>
                      <div class="sort-icon-down">
                        <img
                          v-lazy="`${images}/icon/Vectordown.svg`"
                          alt=""
                          class="img-click"
                          @click="sortSwitch('updateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="uk-table-expand uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="farms.meta.total_count > 0">
            <tr
              v-for="(farm, i) in farms.data"
              :key="i"
            >
              <td>{{ farm.farm_code || "-" }}</td>
              <td>{{ farm.name || "-" }}</td>
              <td>{{ farm.total_cage || "0" }}</td>
              <td class="uk-flex uk-flex-center">
                <label-status :status="farm.status" />
              </td>
              <td>{{ farm.city || "-" }}</td>
              <td>{{ farm.join_date ? formatDate(farm.join_date) : "-" }}</td>
              <td>{{ farm.updated_at ? formatDate(farm.updated_at) : "-" }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Peternakan`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(farm)"
                >
                <img
                  v-if="isCanAccessUser(`edit`, `Peternakan`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="showEdit(farm.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Peternakan`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(farm.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="10"
          />
        </template>
        <template v-else>
          <loading-table :colspan="10" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="farms.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Pagination from "@/components/globals/Pagination"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import LabelStatus from "@/components/globals/LabelStatus"
import { STATUS } from "@/utils/constant"
import { dateUtcParanjeString } from "@/utils/formater"
import { PREFIX_IMAGE } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    LabelStatus
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE,
      sortCode: "updateDown"
    }
  },
  computed: {
    ...mapGetters({
      farms: "farm/farms",
      meta: "farm/meta"
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getFarm(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    this.setMeta({
      ...this.meta,
      ["order_by"]: "updated_at",
      ["sort_by"]: "DESC"
    })
    await this.getFarm(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getFarm: "farm/getFarm"
    }),
    ...mapMutations({
      setMeta: "farm/SET_META",
      setModalDelete: "farm/SET_MODAL_DELETE"
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showEdit(id) {
      this.$router.push(`/admin/peternakan/edit/${id}`)
    },
    showDetail(id) {
      this.$router.push(`/admin/peternakan/detail/${id}`)
    },
    showDeleteConfirmModal(employee) {
      window.UIkit.modal("#modal-delete-confirm").show()
      this.setModalDelete(employee)
    },
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(a) {
      this.sortCode = a
    }
  }
}
</script>
