<template>
  <div
    id="modal-delete-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-delete-farm"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Hapus Peternakan
      </div>
      <p style="font-size:15px">
        Apakah kamu yakin ingin menghapus <br> "{{ modalDelete.name }}" ? <br> Tindakan ini akan menghapus data Kandang dan Kegiatan Kandang di dalam Peternakan tersebut
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleDelete"
        >
          <span
            v-if="is_loading"
            uk-spinner
            class="button-spinner"
          />Ya, hapus
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tidak
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalDelete: 'farm/modalDelete',
      meta: 'farm/meta'
    })
  },
  methods: {
    ...mapActions({
      deleteFarm: 'farm/deleteFarm',
      getFarm: 'farm/getFarm'
    }),
    async handleDelete () {
      try {
        this.is_loading = true
        await this.deleteFarm(this.modalDelete.id)
        const res = await this.$store.getters['farm/getDeleteFarmMsg']
        if (res.message === 'OK') {
          notificationSuccess(`Hapus Peternakan "${this.modalDelete.name}" Berhasil`)
        } else {
          window.UIkit.modal('#modal-delete-failed').show()
        }
        await this.getFarm(this.meta)
        this.is_loading = false
        window.UIkit.modal('#modal-delete-confirm').hide()
      } catch (error) {
        console.log(error.response)
        this.is_loading = false
      }
    }
  }
}
</script>
